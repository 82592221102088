import React, { Component } from 'react';
import {Grid, Button, Box, Hidden} from '@material-ui/core';
import {Image} from 'react-bootstrap'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import BuildIcon from '@material-ui/icons/Build';
import { withStyles } from "@material-ui/core/styles";
import './SideBar.css'
import logo from '../../assets/img/logo.png';


const SideBarButton = withStyles((theme) => ({
    root: {
        width: '100px',
        height: '40px',
        fontSize:'18px',
        fontWeight:'600',
        color:'#ffffff',
        backgroundColor:'#2196F3',
        borderRadius:'10px'

    },
  }))(Button);

class SideBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn:localStorage.getItem('isLoggedIn'),
            dashboardColor: true,
            sandBoxColor: false,
            resultsColor: false,
            profileColor: false,
            resultsColor: false,
        }
    }
    componentDidMount(){
        var link_extension = (window.location.href.split('/').splice(-1)[0])
        if (link_extension===""){
            this.setState({dashboardColor: true,
            sandBoxColor: false,
            resultsColor: false,
            profileColor: false,
            settingsColor: false})
        }
        else if (link_extension==="sandbox"){
            this.setState({dashboardColor: false,
            sandBoxColor: true,
            resultsColor: false,
            profileColor: false,
            settingsColor: false})
        }
        else if (link_extension==="payments"){
            this.setState({dashboardColor: false,
            sandBoxColor: false,
            resultsColor: true,
            profileColor: false,
            settingsColor: false})
        }
        else if (link_extension==="profile"){
            this.setState({dashboardColor: false,
            sandBoxColor: false,
            resultsColor: false,
            profileColor: true,
            settingsColor: false})
        }
        else if (link_extension==="settings"){
            this.setState({dashboardColor: false,
            sandBoxColor: false,
            resultsColor: false,
            profileColor: false,
            settingsColor: true})
        }

    }
    render(){
        return(
            <div>
                {((this.state.isLoggedIn==='true')) ?
                <Grid 
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    >
                    <Box px={'3vw'} py={'8vh'}>
                    <Image src={logo} style={{width:"150px"}} fluid/>
                    </Box>
                    <Box px={'3vw'}>
                            <Box display="flex" className={this.state.dashboardColor? "selected":"inactive"} py={3}>
                                <Box pr={2}>
                                    <DashboardOutlinedIcon fontSize='inherit'/>
                                </Box>
                                <a underline="none" variant="info" className={this.state.dashboardColor? "selected":"inactive"} 
                                    style={{textDecoration: 'none' }} href="/">
                                    Dashboard
                                 </a>
                            </Box>
                            {/* <Box display="flex" className={this.state.sandBoxColor? "selected":"inactive"} py={3} fontSize={18}>
                                <Box pr={2}>
                                    <BuildIcon fontSize='inherit' />
                                </Box>
                                <a underline="none" className={this.state.sandBoxColor? "selected":"inactive"} 
                                    style={{textDecoration: 'none' }} href="/sandbox">
                                    Sandbox
                                 </a>
                            </Box> */}
                            <Box display="flex" className={this.state.resultsColor? "selected":"inactive"} py={3} fontSize={18}>
                                <Box pr={2}>
                                    <AssignmentTurnedInOutlinedIcon fontSize='inherit' />
                                </Box>
                                <a underline="none" className={this.state.resultsColor? "selected":"inactive"} 
                                    style={{textDecoration: 'none' }} href="/payments">
                                    Payments
                                 </a>
                            </Box>
                   
                            {/* <Box display="flex" className={this.state.profileColor? "selected":"inactive"} py={3} fontSize={18}>
                                <Box pr={2}>
                                    <AccountCircleOutlinedIcon fontSize='inherit' />
                                </Box>
                                <a underline="none" className={this.state.profileColor? "selected":"inactive"} 
                                    style={{textDecoration: 'none' }} href="/profile">
                                Profile
                                 </a>
                            </Box> */}

                            <Box display="flex" className={this.state.settingsColor? "selected":"inactive"} py={3} fontSize={18}>
                                <Box pr={2}>
                                    <SettingsOutlinedIcon fontSize='inherit'/>
                                </Box>  
                                <a underline="none" className={this.state.settingsColor? "selected":"inactive"} 
                                    style={{textDecoration: 'none' }} href="/settings">
                                Settings
                                 </a>
                            </Box>
                    </Box>               
                </Grid>
                : null}
                <Box pt={'12vh'} px={'3vw'}>
                    <SideBarButton href='/logout'>Logout</SideBarButton>
                </Box>    
            </div>         
        );
      }
}

export { SideBar };