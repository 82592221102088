import React, { Component } from 'react';
import {Grid, Container} from '@material-ui/core';
import { LoginForm } from '../LoginForm';
import { Dashboard } from '../Dashboard';
import { CashPaymentForm } from '../CashPaymentForm';
import { OnlinePaymentForm } from '../OnlinePaymentForm';
import { SideBar } from '../SideBar';

class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isCashPaymentFormMode:false,
            isOnlinePaymentFormMode:false,
            isLoggedIn:localStorage.getItem('isLoggedIn'),
            showLoginForm:true,
            isDashboardMode: true,
        }
        this.getDataFromDashboard = this.getDataFromDashboard.bind(this)
        this.getDataFromLogin = this.getDataFromLogin.bind(this)
        this.getDataFromCashPaymentForm = this.getDataFromCashPaymentForm.bind(this)
    }

    getDataFromDashboard = function(value) {
        if(value == "CashPaymentForm"){
            this.setState({isDashboardMode: false});
            this.setState({isCashPaymentFormMode: true});
        }
        if(value == "OnlinePaymentForm"){
            this.setState({isDashboardMode: false});
            this.setState({isOnlinePaymentFormMode: true});
        }

        
    }
    getDataFromCashPaymentForm = function() {
        this.setState({isDashboardMode: true});
        this.setState({isCashPaymentFormMode: false});
    }

    getDataFromOnlinePaymentForm = function() {
        this.setState({isDashboardMode: true});
        this.setState({isOnlinePaymentFormMode: false});
    }


    getDataFromLogin = function(value) {
        if(value == true){
            console.log("login confirmed")
            this.setState({isLoggedIn: localStorage.getItem('isLoggedIn'), 
                           showLoginForm:false,
                        });
            }
    }
    componentDidMount(){
        if(localStorage.getItem('isLoggedIn') === 'true'){
            this.setState({showLoginForm:false})
        }
    }
    render()
        {   
        return(
        <div>
            {((this.state.isLoggedIn==='true')) ?
            <div>
                <Grid container>
                    <Grid item md={2}>
                        <SideBar style={{height:"100vh"}} />
                    </Grid>
                    <Grid item md={10}>
                        {this.state.isDashboardMode ? 
                        <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}} maxWidth="xl">
                            <Dashboard sendDataFromDashboardToHome={this.getDataFromDashboard}/>
                        </Container>
                        : null}

                        {this.state.isCashPaymentFormMode ?
                        <Container>
                            <CashPaymentForm sendDataFromCashPaymentFormToHome={this.getDataFromCashPaymentForm}/>
                        </Container>   
                        : null}
                        {this.state.isOnlinePaymentFormMode ?
                        <Container>
                            <OnlinePaymentForm sendDataFromOnlinePaymentFormToHome={this.getDataFromOnlinePaymentForm}/>
                        </Container>   
                        : null}
                    </Grid>
                </Grid>
            </div>
            : null}

            { (this.state.showLoginForm) ?
            <div>
                <LoginForm sendDataFromLoginToHome={this.getDataFromLogin}/>
            </div>
            : null }

             
        </div>
        );
      }
}

export { Home };