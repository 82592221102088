import React, { Component } from 'react';
import {Container, Box, Button, Input, Typography, Paper, FormHelperText,
    InputLabel, Select,  MenuItem, DialogContent, DialogContentText, DialogActions, 
    NativeSelect, Grid, FormControl, TextField, CardContent, CardActions} from '@material-ui/core'
import axios from 'axios';
import { withStyles } from "@material-ui/core/styles";


const SubmitButton = withStyles((theme) => ({
    root: {
        height: '30px',
        fontSize:'12px',
        fontWeight:'400',
        color:'#ffffff',
        backgroundColor:'#2196F3',
        borderRadius:'4px'

    },
  }))(Button);

class CashPaymentForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoggedIn:localStorage.getItem('isLoggedIn'),
            isFormMode:'true',
            isSuccessMode:'false',
            customer_name:"",
            phone_number:"",
            email_address:"",
            amount:"",
            partial_amount:"",
            description:"",
            cash_pos:"",
            description:"",
            installment_number:"",
            customer_nameerrors:"",
            phone_numbererrors:"",
            email_addresserrors:"",
            amounterrors:"",
            partial_amounterrors:"",
            descriptionerrors:"",
            cash_poserrors:"",
            installment_numbererrors:"",
        }
        this.store_cash_transaction = this.store_cash_transaction.bind(this)
    }   

    onChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
    }

    render(){
        if((this.state.isLoggedIn==='false')){
            return <Redirect to = {{ pathname: "/" }} />;
        }
        return(
            <div>
               {((this.state.isLoggedIn==='true'))?
                <div>
                    {((this.state.isFormMode==='true'))?
                    <Grid container>
                        <Grid item md={12}>
                            <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}} maxWidth="xl">
                                <Box px={4}>
                                <Typography variant="h1">
                                    <Box fontSize={36} pt={6}>
                                    Cash Payment Form
                                    </Box>
                                    </Typography>
                                    <Box>
                                        <Typography>
                                            Store cash payment
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box width="80%" px={4} pt={2}>                  
                                <Paper>
                                    <Box px={3} pt={4}>
                                        <Grid container spacing={3}>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Child's name</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <TextField name="customer_name" 
                                                    type="text"
                                                    fullWidth
                                                    onChange={e => this.onChange(e)}
                                                    value={this.state.customer_name} 
                                                    multiline={true}
                                                    id="customer_name_input"
                                                    aria-describedby="customer_name_text"
                                                    variant="outlined"
                                                    size="small"
                                                    />
                                                <FormHelperText id="customer_name_text">{this.state.customer_nameerrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Mobile Number</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <TextField name="phone_number" 
                                                    type="text"
                                                    fullWidth
                                                    onChange={e => this.onChange(e)}
                                                    value={this.state.phone_number} 
                                                    multiline={true}
                                                    id="phone_number_input"
                                                    aria-describedby="phone_number_text"
                                                    variant="outlined"
                                                    size="small"
                                                    />
                                                <FormHelperText id="phone_number_text">{this.state.phone_numbererrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Email Address</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <TextField name="email_address" 
                                                    type="text"
                                                    fullWidth
                                                    onChange={e => this.onChange(e)}
                                                    value={this.state.email_address} 
                                                    multiline={true}
                                                    id="email_address_input"
                                                    aria-describedby="email_address_text"
                                                    variant="outlined"
                                                    size="small"
                                                    />
                                                <FormHelperText id="email_address_text">{this.state.email_addresserrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Program</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <FormControl variant="outlined">
                                                    <NativeSelect
                                                    name="description"
                                                    // labelId="cash-pos-label"
                                                    id="description_input"
                                                    value={this.state.description} 
                                                    onChange={e => this.onChange(e)}
                                                    >   
                                                    <option aria-label="None" value="">Select the program</option>
                                                    <option value="OPD New">OPD New</option>
                                                    <option value="OPD F-up">OPD F-up</option>
                                                    <option value="NHDP Online">NHDP Online</option>
                                                    <option value="NHDP Physical Centre">NHDP Physical Centre</option>
                                                    <option value="E-nable">E-nable</option>
                                                    <option value="Evaluations">Evaluations</option>
                                                    <option value="Re-Evaluations">Re-Evaluations</option>
                                                    </NativeSelect>
                                                </FormControl>
                                                <FormHelperText id="description_text">{this.state.descriptionerrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Total Amount</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <TextField name="amount" 
                                                    type="text"
                                                    fullWidth
                                                    onChange={e => this.onChange(e)}
                                                    value={this.state.amount} 
                                                    multiline={true}
                                                    id="amount_input"
                                                    aria-describedby="amount_text"
                                                    variant="outlined"
                                                    size="small"
                                                    />
                                                <FormHelperText id="amount_text">{this.state.amounterrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Amount collected</Typography>
                                            </Box>
                                            <Box pt={2}>
                                                <TextField name="partial_amount" 
                                                    type="text"
                                                    fullWidth
                                                    onChange={e => this.onChange(e)}
                                                    value={this.state.partial_amount} 
                                                    multiline={true}
                                                    id="partial_amount_input"
                                                    aria-describedby="partial_amount_text"
                                                    variant="outlined"
                                                    size="small"
                                                    />
                                                <FormHelperText id="partial_amount_text">{this.state.partial_amounterrors}</FormHelperText>
                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Cash/POS</Typography>
                                            </Box>
                                            <Box pt={2}>
                                            <FormControl variant="outlined">
                                                <NativeSelect
                                                name="cash_pos"
                                                // labelId="cash-pos-label"
                                                id="cash_pos"
                                                value={this.state.cash_pos} 
                                                onChange={e => this.onChange(e)}
                                                >
                                                <option aria-label="None" value="" />
                                                <option value="cash">Cash</option>
                                                <option value="pos">POS</option>
                                                </NativeSelect>
                                            </FormControl>
                                            <FormHelperText id="cash_pos_text">{this.state.cash_poserrors}</FormHelperText>

                                            </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                            <Box>
                                            <Typography>Installment No.</Typography>
                                            </Box>
                                            <Box pt={2}>
                                            <FormControl variant="outlined">
                                                <NativeSelect
                                                name="installment_number"
                                                // labelId="cash-pos-label"
                                                id="installment_number"
                                                value={this.state.installment_number} 
                                                onChange={e => this.onChange(e)}
                                                >
                                                <option aria-label="None" value="" />
                                                <option value="1st">1st</option>
                                                <option value="2nd">2nd</option>
                                                </NativeSelect>
                                            </FormControl>
                                            <FormHelperText id="installment_number_text">{this.state.installment_numbererrors}</FormHelperText>

                                            </Box>
                                            </Grid>
                                        
                                        </Grid>
                                        <Box py={2}>
                                        <SubmitButton variant="contained" color="primary" onClick={this.store_cash_transaction}>Store Cash Payment</SubmitButton>
                                        </Box>
                                    </Box>
                                </Paper>
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>:null}
                </div>:null}
                    {(this.state.isSuccessMode==='true')?
                    <div>
                        <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}}>
                        <Box px={4}>
                                <Typography variant="h1">
                                <Box fontSize={36} pt={6}>
                                    Cash Payment Form
                                    </Box>
                                    </Typography>
                                    <Box>
                                        <Typography>
                                            Store cash payments
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box p={4}>
                                    <Paper>
                                        <Box p={6}>
                                            <Typography>
                                                You have successfully stored a cash payment to the customer
                                            </Typography>
                                            <Box>
                                                <SubmitButton variant="contained" color="primary" href='/payments'>Review the payment transactions</SubmitButton>
                                            </Box>
                                        </Box>
                                    </Paper>                              
                                </Box>   
                    </Container>
                    </div>:null}
           </div> 
        );
    }
    store_cash_transaction = function(){
        this.clearErrorMessages();
        axios.post("https://api.newhorizonscdc.in/payments/store-cash-transaction/",{
            "created_by": localStorage.getItem("email"),
            "customer_name" : this.state.customer_name,
            "phone_number" : this.state.phone_number,
            "email_address" : this.state.email_address,
            "amount" : this.state.amount,
            "partial_amount" : this.state.partial_amount,
            "description" : this.state.description,
            "cash_pos": this.state.cash_pos,
            "installment_number": this.state.installment_number,
        })
        .then(response=>{
            console.log(response.data)
            this.setState({isFormMode:'false',isSuccessMode:'true'})
        })
        .catch(error=>{
            if (error.response.data["error_type"] == "patient_name") {
                this.setState({customer_nameerrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "phone_number") {
                this.setState({phone_numbererrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "email_address") {
                this.setState({email_addresserrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "amount") {
                this.setState({amounterrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "partial_amount") {
                this.setState({partial_amounterrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "description") {
                this.setState({descriptionerrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "cash_pos") {
                this.setState({cash_poserrors:error.response.data["results"]})
            }
            if (error.response.data["error_type"] == "installment_number") {
                this.setState({installment_numbererrors:error.response.data["results"]})
            } 
        })
        }
        clearErrorMessages =  function(){
            this.setState({customer_nameerrors:"",
            phone_numbererrors:"",
            email_addresserrors:"",
            amounterrors:"",
            partial_amounterrors:"",
            descriptionerrors:"",
            cash_poserrors:"",
            installment_numbererrors:""})
        }
}



export { CashPaymentForm };