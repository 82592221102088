import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import { Paper, Box, Grid, Typography, Button,
     FormHelperText, Input, Container, Hidden,
     FormControl, InputLabel, Avatar} from '@material-ui/core';
import {Image} from 'react-bootstrap'
// import './LoginForm.css';
import goalseeker from '../../assets/img/goalseeker.png';
import axios from 'axios';

class LoginForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoggedIn: false,
            email:'',
            password:'',
            emailerrors:'',
            passworderrors:'',
            non_field_errors:''
        }

        this.successfulLogin = this.successfulLogin.bind(this)
        this.performLogin = this.performLogin.bind(this)
    }
    successfulLogin = function(){
        this.props.sendDataFromLoginToHome(true);       
    }

    performLogin = function () {
        axios.post('https://api.newhorizonscdc.in/rest-auth/login/', {
      'email': this.state.email,
      'password': this.state.password
      })
      .then(response => {
          console.log(response.data)
          localStorage.setItem("email", this.state.email);
          localStorage.setItem("isLoggedIn", true);
          this.successfulLogin()
      })
      .catch(error => {
        console.log(error.response.data)
        this.setState({emailerrors:error.response.data['email']})
        this.setState({passworderrors:error.response.data['password']})
        this.setState({non_field_errors:error.response.data['non_field_errors']})
      });
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        this.performLogin()
        
    }


    onChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
    }
    render(){
        if (this.state.isLoggedIn) {
            // redirect to home if signed up
            return <Redirect to = {{ pathname: "/" }} />;
          }
        return(
            <div>
                <Container maxWidth="lg">
                <Box  width="100%" display="flex" p={15}>
                    <Paper elevation={3}>
                    <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}>
                        <Hidden smDown><Grid item md>
                            <Image src={goalseeker} fluid/>
                        </Grid>
                        </Hidden>
                        <Grid item md>
                            <Box p={2}>
                            <Typography variant="h5" gutterBottom>
                                Login into the portal
                            </Typography>
                                <form onSubmit={this.mySubmitHandler}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="email-input">Email address</InputLabel>
                                            <Input name="email" 
                                                type="email" 
                                                onChange={e => this.onChange(e)}
                                                value={this.state.email} 
                                                id="email-input"
                                                aria-describedby="email-helper-text" />
                                            <FormHelperText id="email-helper-text">{this.state.emailerrors}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="password-input">Password</InputLabel>
                                            <Input name="password" 
                                                type="password" 
                                                onChange={e => this.onChange(e)}
                                                value={this.state.password} 
                                                id="password-input"
                                                aria-describedby="password-helper-text" />
                                            <FormHelperText id="password-helper-text">{this.state.passworderrors}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {this.state.non_field_errors}
                                </Typography>
                                <Grid>
                                    <Button href="/changepassword" 
                                            disableFocusRipple 
                                            disableRipple 
                                            style={{ textTransform: "none" }} 
                                            variant="text" 
                                            color="primary">
                                        Forgot password ?
                                    </Button>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item>
                                            <Button type="submit" variant="contained" color="primary">Login</Button>
                                    </Grid>
                                </Grid>
                                
                                </form>

                            </Box>
                        </Grid>
                    </Grid>
                    </Paper>
                </Box>
                </Container>
            </div>
        );
    }
}

export { LoginForm };