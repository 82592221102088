import React, { Component } from 'react';
import {Container, Box, Button, Input, Typography, Paper, FormHelperText,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
    NativeSelect, Grid, FormControl, InputBase, Card, CardContent, CardActions} from '@material-ui/core'

import { withStyles } from "@material-ui/core/styles";


const SubmitButton = withStyles((theme) => ({
    root: {
        height: '30px',
        fontSize:'12px',
        fontWeight:'400',
        color:'#ffffff',
        backgroundColor:'#2196F3',
        borderRadius:'4px'

    },
  }))(Button);

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.openCashPaymentForm = this.openCashPaymentForm.bind(this)
        this.openOnlinePaymentForm = this.openOnlinePaymentForm.bind(this)
        
    }
    openCashPaymentForm =  function(){
        this.props.sendDataFromDashboardToHome("CashPaymentForm")
    }

    openOnlinePaymentForm =  function(){
        this.props.sendDataFromDashboardToHome("OnlinePaymentForm")
    }
    render(){
        return(
            <div>
                <Grid container>
                    <Grid item md={10}>
                        <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}} maxWidth="xl">
                            <Box px={4}>
                            <Typography variant="h1">
                                <Box fontSize={36} pt={6}>
                                    Dashboard
                                </Box>
                                </Typography>
                                <Box>
                                    <Typography>
                                        Send payment links to patients, input cash payments
                                    </Typography>
                                </Box>
                            </Box>
                            <Box p={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Card>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Cash Payment
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    You can store cash payments collected in the centres
                                                </Typography>
                                                <br></br>
                                            </CardContent>
                                            <CardActions>
                                                <SubmitButton variant="contained" color="primary" size="small" onClick={this.openCashPaymentForm}>Store cash payments</SubmitButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Online Payments
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Send payment links to the patient for payments using Netbanking, Debit/Credit Card, UPI, Wallets
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <SubmitButton  variant="contained" color="primary" size="small" onClick={this.openOnlinePaymentForm}>Send payment link</SubmitButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </Grid>
                                

                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </div>
        );
      }

}

export { Dashboard };