import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import {Container, Image, Form, Button, Row, Col} from 'react-bootstrap';
import './Logout.css';
import axios from 'axios';

class Logout extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }   

    componentDidMount(){
        localStorage.setItem("isLoggedin",false)
        localStorage.clear()

    }

    render(){
            return <Redirect to = {{ pathname: "/" }} />;
          }

}

export { Logout };