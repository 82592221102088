import React, { Component } from 'react';
import {Container, Box, Button, Input, Typography, Paper, TableRow,
    Table, TableCell, TableBody, TableHead, DialogActions, 
    NativeSelect, Grid, FormControl, InputBase, AppBar, Tabs, Tab} from '@material-ui/core'
import {TabPanel, TabContext, TabList} from '@material-ui/lab';
import { SideBar } from '../SideBar';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';


class Payments extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            isLoggedIn:localStorage.getItem('isLoggedIn'),
            defaultTab:'1',
            payment_transactions:[{'payment_transaction_id': 'pay_HEMvwEmoQbYb88', 'amount': '400.0', 'status': 'captured', 'method': 'upi', 'method_detail': 'subhash@oksbi', 'acquirer_data': '160648318743', 'error_code': 'None', 'error_description': 'None', 'error_source': 'None', 'error_step': 'None', 'error_reason': 'None'}],
            cash_transactions:[{'created_by': 1, 'customer_name': 'Subhash', 'customer_number': '+919619647014', 'customer_email': 'subhaoi@gmail.com', 'description': 'Test Cash payment', 'amount': '1000', 'amount_paid': '100','cash_pos':'Cash'}],
            payment_links:[{'created_by': 1, 'payment_link_id': 'plink_HEU7znyuBfoy1m', 'customer_name': 'Subhash', 'customer_number': '+919619647014', 'customer_email': 'subhaoi@gmail.com', 'description': 'Test Payment 24th May 2021', 'amount': '1000.0', 'amount_paid': '0.0', 'status': 'created'}]
        }
    }   

    componentDidMount(){
        axios.post('https://api.newhorizonscdc.in/payments/get-payment-transactions/',{
            "created_by": localStorage.getItem("email"),
        })
          .then(response => {
          this.setState({payment_transactions:response.data['result']})
          })
          .catch(function (error) {
            // console.log(error);
          });
          axios.post('https://api.newhorizonscdc.in/payments/get-cash-transactions/',{
            "created_by": localStorage.getItem("email"),
        })
          .then(response => {
          this.setState({cash_transactions:response.data['result']})
          })
          .catch(function (error) {
            // console.log(error);
          });
          axios.post('https://api.newhorizonscdc.in/payments/get-payment-links/',{
            "created_by": localStorage.getItem("email"),
        })
          .then(response => {
          this.setState({payment_links:response.data['result']})
          })
          .catch(function (error) {
            // console.log(error);
          });
    }
    onChange = (e) => {
        console.log(e.target.textContent)
        if(e.target.textContent == "Payment Transactions"){
            this.setState({
                defaultTab: "1",
            });
        }
        if(e.target.textContent == "Cash Transactions"){
            this.setState({
                defaultTab: "2",
            });
        }
        if(e.target.textContent == "Payment Links"){
            this.setState({
                defaultTab: "3",
            });
        }
    }
    render(){ 
        let payment_transaction_rows, payment_transaction_table, cash_transaction_rows, cash_transaction_table, payment_link_rows, payment_link_table;
        payment_transaction_rows = this.state.payment_transactions.map((value) =>(
            <TableRow key={value.id}>
                <TableCell>
                    {value.receipt_code__receipt_code}
                </TableCell>
                <TableCell>
                    {value.created}
                </TableCell>
                <TableCell>
                    {value.customer_name}
                </TableCell>
                <TableCell>
                    {value.amount}
                </TableCell>
                <TableCell>
                    {value.status}
                </TableCell>
                <TableCell>
                    {value.method}
                </TableCell>
                {/* <TableCell>
                    {value.method_detail}
                </TableCell> */}
                <TableCell>
                    {value.error_description}
                </TableCell>
                <TableCell>
                    {value.error_source}
                </TableCell>
                {/* <TableCell>
                    {value.error_step}
                </TableCell>
                <TableCell>
                    {value.error_reason}
                </TableCell> */}
            </TableRow>
        ));
        payment_transaction_table = (
            <Table size="small">
                <TableHead>
                    <TableRow key='payment'>
                        <TableCell>
                            Receipt Code
                        </TableCell>
                        <TableCell>
                            Date
                        </TableCell>
                        <TableCell>
                            Customer Name
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            Method
                        </TableCell>
                        {/* <TableCell>
                            Method Detail
                        </TableCell> */}
                        <TableCell>
                            Error Description
                        </TableCell>
                        <TableCell>
                            Error Source
                        </TableCell>
                        {/* <TableCell>
                            Error Steps
                        </TableCell>
                        <TableCell>
                            Error Reason
                        </TableCell> */}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {payment_transaction_rows}
                    </TableBody>
            </Table>
        );
        cash_transaction_rows = this.state.cash_transactions.map((value) =>(
            <TableRow  key={value.id}>
                <TableCell>
                    {value.receipt_code__receipt_code}
                </TableCell>
                <TableCell>
                    {value.created}
                </TableCell>
                <TableCell>
                    {value.customer_name}
                </TableCell>
                <TableCell>
                    {value.description}
                </TableCell>
                <TableCell>
                    {value.amount}
                </TableCell>
                <TableCell>
                    {value.amount_paid}
                </TableCell>
                <TableCell>
                    {value.cash_pos}
                </TableCell>
                <TableCell>
                    {value.installment_number}
                </TableCell>
            </TableRow>
        ));
        cash_transaction_table = (
            <Table>
                <TableHead>
                    <TableRow key='cash'>
                        <TableCell>
                            Receipt Code
                        </TableCell>
                        <TableCell>
                            Date
                        </TableCell>
                        <TableCell>
                            Customer Name
                        </TableCell>
                        <TableCell>
                            Description
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Amount Paid
                        </TableCell>
                        <TableCell>
                            Cash/POS
                        </TableCell>
                        <TableCell>
                            Installment
                        </TableCell>
                    </TableRow>
                </TableHead> 
                <TableBody>
                {cash_transaction_rows}
                </TableBody>
            </Table>
        );
        payment_link_rows = this.state.payment_links.map((value) => (
            <TableRow  key={value.id}>
                <TableCell>
                    {value.payment_link_id}
                </TableCell>
                <TableCell>
                    {value.customer_name}
                </TableCell>
                <TableCell>
                    {value.customer_number}
                </TableCell>
                <TableCell>
                    {value.customer_email}
                </TableCell>
                <TableCell>
                    {value.description}
                </TableCell>
                <TableCell>
                    {value.amount}
                </TableCell>
                <TableCell>
                    {value.amount_paid}
                </TableCell>
                <TableCell>
                    {value.status}
                </TableCell>
            </TableRow>
        ));
        payment_link_table = (
            <Table>
                <TableHead>
                    <TableRow key='test_date'>
                        <TableCell>
                            Payment Link ID
                        </TableCell>
                        <TableCell>
                            Customer Name
                        </TableCell>
                        <TableCell>
                            Customer Number
                        </TableCell>
                        <TableCell>
                            Customer Email
                        </TableCell>
                        <TableCell>
                            Description
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Amount Paid
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead> 
                <TableBody>
                {payment_link_rows}
                </TableBody>
            </Table>
        );
        return(
        <div>
            {((this.state.isLoggedIn==='true')) ?
            <div> 
                <Grid container>
                    <Grid item md={2}>
                        <SideBar />
                    </Grid>
                    <Grid item md={10}>
                        <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}} maxWidth="xl">
                            <Box px={4}>
                                <Typography variant="h1">
                                <Box fontSize={36} pt={6}>
                                    Payments
                                </Box>
                                </Typography>
                                <Box>
                                    <Typography>
                                        Check the payment transactions, track payment links, send reminders
                                    </Typography>
                                </Box>
                                <Box pt={6}>
                                    <Paper>
                                    <TabContext value={this.state.defaultTab}>
                                        <AppBar position="static">
                                        <TabList onClick={this.onChange} variant="fullWidth" aria-label="simple tabs example">
                                            <Tab label="Payment Transactions" id="1" value="1"/>
                                            <Tab label="Cash Transactions" id="2" value="2" />
                                            <Tab label="Payment Links" id="3" value="3" />
                                        </TabList>
                                        </AppBar>
                                        <TabPanel value="1">{payment_transaction_table}</TabPanel>
                                        <TabPanel value="2">{cash_transaction_table}</TabPanel>
                                        <TabPanel value="3">{payment_link_table}</TabPanel>
                                    </TabContext>
                                    </Paper>
                                    
                                </Box>
                            </Box>    
                        </Container>
                    </Grid>
                </Grid>        
            </div> 
            : null}                    
        </div>
      
        );
        }
}

export { Payments };