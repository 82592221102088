import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Home } from '../WebComponents/Home';
import { Settings } from '../WebComponents/Settings';
import { Logout } from '../WebComponents/Logout';
import { Payments } from '../WebComponents/Payments';

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
    }

  }

  render(){
    return(
      <div>
    
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path="/settings" component={Settings}/>
          <Route path="/logout" component={Logout}/>
          <Route path="/payments" component={Payments}/>
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
      </div>
    );
  }
}
export { App };