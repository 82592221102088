import React, { Component } from 'react';
import {Container, Box, Button, Input, Typography, Paper, FormHelperText,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
    NativeSelect, Grid, FormControl, InputBase} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import { SideBar } from '../SideBar';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';


const ForgotButton = withStyles((theme) => ({
    root: {
        width: '200px',
        fontWeight:'600',
        fontSize:'15px',
        backgroundColor:'#ffffff',
        color:'#EB5757',
        margin:'0',
        padding:'0',

    },
  }))(Button);

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 10,
      position: 'relative',
      width:'250px',
      height:'35px',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #DDDDDD',
      fontSize: 12,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: 'Mulish',
      '&:focus': {
        borderRadius: 10,
        borderColor: '#DDDDDD',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  const SaveButton = withStyles((theme) => ({
    root: {
        width: '80px',
        height:'30px',
        borderRadius:'10px',
        fontSize:'16px',
        backgroundColor:'#27AE60',
        color:'#ffffff',

    },
  }))(Button);

  const CustomInputBox = withStyles((theme) =>({
    root:{
        width:"250px",
    }
  }))(NativeSelect);
class Settings extends Component{
    constructor(props) {
        super(props);

        this.state = {
            open:false,
            setOpen:false,
            isLoggedIn:localStorage.getItem('isLoggedIn'),
            participantSettings:"",
            username:"",
            dialogOpen:false,
            password_disabled:true,
            passworderrors:"",
            email_notifications_editModeEnabled:true,
            email_notifications:"",
            email_notifications_disabled:false,
            email_notificationserrors:"",
            subscription_end_date_editModeEnabled:true,
            subscription_end_date:"",
            subscription_end_date_disabled:true,
            subscription_end_dateerrors:"",
            sms_notification_editModeEnabled:true,
            sms_notification:"",
            sms_notification_disabled:false,
            sms_notificationerrors:"",
            
        }
        this.password_handleEditClick = this.password_handleEditClick.bind(this)
        this.email_notifications_handleEditClick = this.email_notifications_handleEditClick.bind(this)
        this.email_notifications_handleDoneClick = this.email_notifications_handleDoneClick.bind(this)   
        this.email_notifications_keyPress = this.email_notifications_keyPress.bind(this)
        this.sms_notification_handleEditClick = this.sms_notification_handleEditClick.bind(this)
        this.sms_notification_handleDoneClick = this.sms_notification_handleDoneClick.bind(this)   
        this.sms_notification_keyPress = this.sms_notification_keyPress.bind(this)
        this.settings_handleDoneClick = this.settings_handleDoneClick.bind(this)
    }

    componentDidMount() {
      axios.post('https://api.nsync.in/participants/get-participant-settings/', {
      'email': localStorage.getItem("email"),
      })
      .then(response => {
            // console.log(response.data)
            this.setState({participantSettings:response.data['results']})
            this.setState({username:response.data['results'][0]['username']})
            this.setState({email_notifications:response.data['results'][0]['email_notifications']})
            this.setState({sms_notification:response.data['results'][0]['sms_notification']})
            this.setState({subscription_end_date:response.data['results'][0]['subscription_end_date']})
            // console.log(this.state.email_notifications)
      })
      .catch(function (error) {
        // console.log(error.response.data);
      });
    }

    render(){  
        if((this.state.isLoggedIn==='false')){
            return <Redirect to = {{ pathname: "/" }} />;
        }
        return(
        <div>
            {((this.state.isLoggedIn==='true')) ?
            <div> 
                <Grid container>
                    <Grid item md={2}>
                        <SideBar />
                    </Grid>
                    <Grid item md={10}>
                        <Container style={{backgroundColor:"#E5E5E5", height:"100vh"}} maxWidth="xl">
                            <Box px={4}>
                                <Typography variant="h1">
                                <Box fontSize={36} pt={10}>
                                    Settings
                                </Box>
                                </Typography>
                                <Box>
                                    <Typography>
                                    Change contact preferences, notifications and much more
                                    </Typography>
                                </Box>
                            </Box>

                            <Box width="80%" px={4} pt={2}>                  
                            <Paper> 
                            <Box px={6} pt={4}>
                                <Grid container spacing={3}>
                                <Grid item sm={6}>
                                <Box>
                                    Username
                                </Box>
                                <Box pt={2}>
                                    <BootstrapInput name="username" 
                                        type="text"
                                        disabled={true}
                                        fullWidth
                                        onChange={e => this.onChange(e)}
                                        onKeyDown={this.username_keyPress}
                                        value={this.state.username} 
                                        multiline={true}
                                        id="username_input"
                                        aria-describedby="username_text" />
                                    <FormHelperText id="usernamer_text">{this.state.usernameerrors}</FormHelperText>
                                </Box>
                                </Grid>
                                <Grid item sm={6}>
                                    <Box>
                                        Password
                                    </Box>
                                    <Box pt={2}>
                                        <BootstrapInput name="password" 
                                            type="text"
                                            disabled={this.state.password_disabled}
                                            onChange={e => this.onChange(e)}
                                            onKeyDown={this.password_keyPress}
                                            value="**********" 
                                            multiline={true}
                                            id="password_input"
                                            aria-describedby="password_text" />
                                        <FormHelperText id="password_text">{this.state.passworderrors}</FormHelperText>
                                    </Box>
                                    <Box>
                                    <ForgotButton startIcon={<EditIcon />} onClick={this.password_handleEditClick}>Change Password</ForgotButton>
                                    </Box>
                                    <Dialog open={this.state.dialogOpen}>
                                        <DialogTitle>Are you sure you want to change your password?</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                            You will be logged out of your current session and 
                                            you will have to reset the password using your email address
                                            </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.handleNo} color="primary">
                                                    No
                                                </Button>
                                                <Button onClick={this.handleYes} color="primary" autoFocus>
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                    </Dialog>
                                </Grid>
                                
                                </Grid>
                                <Box pb={2} display="flex"  justifyContent="flex-end">
                                    <SaveButton onClick={this.settings_handleDoneClick}>Save</SaveButton>
                                </Box>  
                            </Box>
                            </Paper>
                            </Box>
                            
                        </Container>
                    </Grid>
                </Grid>        
                    </div> 
                    : null}
                
        </div>
        );
      }

    
      handleNo = (e) => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
      };

      handleYes = (e) => {
        localStorage.clear()
        this.props.history.push('/changepassword');
      };

      onChange = (e) => {
        // console.log(e.target.name)

        this.setState({
          [e.target.name]: e.target.value,
        });
    }

    
     password_handleEditClick = function() {
        this.setState({ dialogOpen: !this.state.dialogOpen });
      }
    

    email_notifications_keyPress = function(e){
        if(e.keyCode == 13){
           this.email_notifications_handleDoneClick()
        }
     }

     settings_handleDoneClick = function(){
        this.email_notifications_handleDoneClick()
        // this.setState({ company_disabled: true });
        // this.setState({ skills_disabled: true });
        // this.setState({ expected_salary_disabled: true });
        // this.setState({ current_salary_disabled: true });
        // this.setState({ employment_type_disabled: true });
        // this.setState({ professionalDetails_editModeEnabled: !this.state.professionalDetails_editModeEnabled });
    }
    
     email_notifications_handleEditClick = function() {
        this.setState({ email_notifications_editModeEnabled: !this.state.email_notifications_editModeEnabled });
        this.setState({ email_notifications_disabled: !this.state.email_notifications_disabled });
      }
    
    email_notifications_handleDoneClick = function() {
        // this.setState({ email_notifications_editModeEnabled: !this.state.email_notifications_editModeEnabled });
        // this.setState({ email_notifications_disabled: !this.state.email_notifications_disabled });
        axios.post("https://api.nsync.in/participants/update-participant-email-notifications/",{
        'email': localStorage.getItem("email"),
        'email_notifications':this.state.email_notifications
    })
    .then(response=>{
        // console.log(response.data)
        this.sms_notification_handleDoneClick()
        this.setState({email_notificationserrors:""})
    })
    .catch(error=>{
        // console.log(error.response.data)
        this.setState({email_notificationserrors:error.response.data['results']})
        this.setState({email_notifications:this.state.personalDetails[0]['email_notifications']})
    })
    }
    sms_notification_keyPress = function(e){
        if(e.keyCode == 13){
           this.sms_notification_handleDoneClick()
        }
     }
    
     sms_notification_handleEditClick = function() {
        this.setState({ sms_notification_editModeEnabled: !this.state.sms_notification_editModeEnabled });
        this.setState({ sms_notification_disabled: !this.state.sms_notification_disabled });
      }
    
    sms_notification_handleDoneClick = function() {
        // this.setState({ sms_notification_editModeEnabled: !this.state.sms_notification_editModeEnabled });
        // this.setState({ sms_notification_disabled: !this.state.sms_notification_disabled });
        axios.post("https://api.nsync.in/participants/update-participant-sms-notification/",{
        'email': localStorage.getItem("email"),
        'sms_notification':this.state.sms_notification
    })
    .then(response=>{
        // console.log(response.data)
        this.setState({sms_notificationerrors:""})
    })
    .catch(error=>{
        // console.log(error.response.data)
        this.setState({sms_notificationerrors:error.response.data['results']})
        this.setState({sms_notification:this.state.personalDetails[0]['sms_notification']})
    })
    }
}

export { Settings };